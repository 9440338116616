<template>
  <div style="width: 1px; height: 1px; overflow: hidden">
    <img id="croppr-image" />
  </div>
</template>

<script>
import imageApi from "@/apis/image";
import "cropperjs/dist/cropper.css";
import Cropperjs from "cropperjs";

export default {
  data() {
    return {
      cropper: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initCropper();
    });
  },
  methods: {
    initCropper() {
      const image = document.getElementById("croppr-image");
      this.cropper = new Cropperjs(image);
    },
    async uploadImage() {
      const imageData = this.cropper.getImageData();
      const croppedImage = this.cropper
        .setData({
          x: 0,
          y: 0,
          width: imageData.naturalWidth,
          height: imageData.naturalHeight,
        })
        .getCroppedCanvas({ maxWidth: 1400, maxHeight: 1400 })
        .toDataURL("image/jpeg", 0.5);

      let response = await imageApi.uploadByBase64(croppedImage);
      return response.data.data.image;
    },
  }
}
</script>
